// 定义项目案例相关的API接口函数
import request from '@/utils/request';

export const findCaseByCategoryId = (params) => {
  params.appId = 10002
  return request('/front/casew/case/lists', 'post', params)
}

/**
 * 获取案例类别
 * @returns {*}
 */
export const findCaseCategory = () => {
  return request('/front/casew/category/index?url=&appId=10002', 'get')
}

export const findCase = (caseId) => {
  return request('/front/casew/case/detail?appId=10002', 'get', {caseId})
}

