<template>
  <div class='base-case-page'>
    <div class="container" v-if="!loading && aCase">
      <BaseBread>
        <BaseBreadItem to="/">首页</BaseBreadItem>
        <BaseBreadItem to="/casew">案例中心</BaseBreadItem>
        <BaseBreadItem>{{ aCase.caseName }}</BaseBreadItem>
      </BaseBread>
      <div class="case-info">
        <div class="spec">
          <CaseName :aCase="aCase"/>
          <div class="media">
            <CaseImage :images="aCase.image"/>
          </div>
          <div class="video-play" v-if="aCase.videoId!==0">
            <BaseVideoPlayer :options="videoOption"></BaseVideoPlayer>
          </div>
          <div>
            <div class="content-box" v-html="aCase.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading"></div>
  </div>
</template>

<script>
import CaseName from "./case-name";
import {nextTick, provide, ref, watch, reactive} from 'vue'
import {useRoute} from 'vue-router'
import {findCase} from "@/api/case";
import CaseImage from "@/views/casew/components/case-image.vue";
import BaseVideoPlayer from "@/components/library/base-video-player.vue";
import BaseBread from "@/components/library/base-bread.vue";
import BaseBreadItem from "@/components/library/base-bread-item.vue";

export default {
  name: 'CaseDetail',
  components: {BaseBreadItem, BaseBread, BaseVideoPlayer, CaseImage, CaseName},
  setup() {
    const {aCase, loading, videoOption} = useCase()
    // 提供aCase数据给后代组件使用
    provide('aCase', aCase)

    return {aCase, loading, videoOption}
  }
}
// 获取案例详情
const useCase = () => {
  // 出现路由地址产品ID发生变化，但是不会重新初始化组件
  const aCase = ref(null)
  const route = useRoute()
  const loading = ref(false)
  const videoOption = ref(null)
  watch(() => route.params.caseId, (caseVal) => {
    if (caseVal && `/casew/detail/${caseVal}` === route.path) {
      loading.value = true
      findCase(route.params.caseId).then(data => {
        // 让案例数据为null然后使用v-if的组件可以重新销毁和创建
        aCase.value = null
        nextTick(() => {
          aCase.value = data.data.detail
          loading.value = false
        })
        videoOption.value = {
          autoplay: true,
          controls: true,
          sources: [
            {
              src: data.data.detail.videoFilePath,
              type: "video/mp4",
            },
          ],
          width: "870px",
          height: "490px",
        };

      })
    }
  }, {immediate: true})
  return {aCase, loading, videoOption}
}
</script>

<style scoped lang='less'>
.loading {
  height: 580px;
  width: 1240px;
  margin: 72px auto 20px;
  background: rgba(255, 255, 255, .9) url(../../../assets/images/loading.gif) no-repeat center;
}

.case-info {
  min-height: 600px;
  background: #fff;
  display: flex;

  .media {
    margin-left: 150px;
    width: 580px;
    height: 500px;
    padding: 30px 50px;
  }

  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}

.case-footer {
  display: flex;
  margin-top: 20px;

  .case-article {
    width: 940px;
    margin-right: 20px;
  }

  .case-aside {
    width: 280px;
    min-height: 1000px;
  }
}

.video-play {
  width: 73.5%;
  margin: 50px;
  display: grid;
  justify-content: center;
  align-items: center;

}

.content-box {
  width: 100%;
  padding: 50px;
}
</style>
